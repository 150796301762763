import './styles/bridgedialogue.css';

import confirmTransfer
  from '../assets/confirm-transfer.3a6feef5fc25379ea7611cd41e900cec.svg';

const BridgeDialogue = ({handleBridgeDialogOpen, action, selectedToken, tokenAmount, selectedFromChain, selectedToChain, isLoading }) => {
  
    return(
<div className="chakra-modal__overlay css-14h5w54" style={{ opacity: 1 }}>
  <div
    data-focus-guard="true"
    tabIndex={0}
    data-aria-hidden="true"
    aria-hidden="true"
    style={{
      width: 1,
      height: 0,
      padding: 0,
      overflow: "hidden",
      position: "fixed",
      top: 1,
      left: 1
    }}
  />
  <div
    data-focus-guard="true"
    tabIndex={1}
    data-aria-hidden="true"
    aria-hidden="true"
    style={{
      width: 1,
      height: 0,
      padding: 0,
      overflow: "hidden",
      position: "fixed",
      top: 1,
      left: 1
    }}
  />
  <div data-focus-lock-disabled="false">
    <div className="chakra-modal__content-container css-1yb2anq" tabIndex={-1}>
      <section
        className="chakra-modal__content css-onabad"
        role="dialog"
        id="chakra-modal-16"
        tabIndex={-1}
        aria-modal="true"
        aria-describedby="chakra-modal--body-16"
        style={{ opacity: 1, transform: "none" }}
      >
        <button
          type="button"
          aria-label="Close"
          className="chakra-modal__close-btn css-111mb8s"
          onClick={handleBridgeDialogOpen}
        >
          <svg
            viewBox="0 0 24 24"
            focusable="false"
            className="chakra-icon css-onkibi"
            aria-hidden="true"
          >
            <path
              fill="currentColor"
              d="M.439,21.44a1.5,1.5,0,0,0,2.122,2.121L11.823,14.3a.25.25,0,0,1,.354,0l9.262,9.263a1.5,1.5,0,1,0,2.122-2.121L14.3,12.177a.25.25,0,0,1,0-.354l9.263-9.262A1.5,1.5,0,0,0,21.439.44L12.177,9.7a.25.25,0,0,1-.354,0L2.561.44A1.5,1.5,0,0,0,.439,2.561L9.7,11.823a.25.25,0,0,1,0,.354Z"
            />
          </svg>
        </button>
        <div
          className="chakra-modal__body css-hwpfd6"
          id="chakra-modal--body-16"
        >
          <div className="css-1ubcta3">
            <div className="css-7wojxp">
              <div className="css-cseyqw">
                <img
                  src={selectedToken.logoURI}
                  className="chakra-image css-0"
                />
                <p className="chakra-text css-1cfe0k4">{(tokenAmount).toLocaleString(undefined, { maximumFractionDigits: 4})}</p>
                <p className="chakra-text css-1pygem4">{selectedToken.symbol.replace(/( from PulseChain| from Ethereum)/g, '')}</p>
              </div>
            </div>
            <div className="css-1bclc0e">
              <img
                src={confirmTransfer} 
                className="chakra-image css-1l7jkb0"
              />
            </div>
            <div className="css-7wojxp">
              <div className="css-cseyqw">
                <img
                  src={selectedToken.logoURI}
                  className="chakra-image css-0"
                />
                <p className="chakra-text css-1cfe0k4">{(tokenAmount).toLocaleString(undefined, { maximumFractionDigits: 4})}</p>
                <p className="chakra-text css-1pygem4">{selectedToken.symbol.replace(/( from PulseChain| from Ethereum)/g, '')}</p>
              </div>
            </div>
          </div>
          <div className="css-96du33">Bridge Fees 0%</div>
          <div className="css-1if06x3">
{/*          <div>
            <div className="css-166sium">
              <div className="css-70qvj9">
                <svg
                  viewBox="0 0 14 14"
                  focusable="false"
                  className="chakra-icon css-19amq0i"
                >
                  <g fill="currentColor">
                    <polygon points="5.5 11.9993304 14 3.49933039 12.5 2 5.5 8.99933039 1.5 4.9968652 0 6.49933039" />
                  </g>
                </svg>
              </div>
              <div className="css-1wmrhmx">
                <p className={"chakra-text css-1oe4oai"}>
                  Authorize Bridge to access your 
                  <b className="chakra-text css-0"> 400,000.0 {selectedToken.symbol}</b>
                </p>
              </div>
            </div>
          </div>*/}
            <div className="css-166sium">
              <div className="css-70qvj9">
                <svg
                  viewBox="0 0 14 14"
                  focusable="false"
                  className={'chakra-icon css-1p04zad' /*css-v9m5uc css-1p04zad*/}
                >
                  <g fill="currentColor">
                    <polygon points="5.5 11.9993304 14 3.49933039 12.5 2 5.5 8.99933039 1.5 4.9968652 0 6.49933039" />
                  </g>
                </svg>
                {/*isLoading && 
                <div className="chakra-spinner css-1kyw2a1">
                <span className="css-f8n5zr">Loading...</span>
              </div>
        */}
              </div>
              <div className="css-1wmrhmx">
                <span className={`chakra-text css-fayrjd` /*css-1k6geo css-fayrjd*/}>
                  Send <b className="chakra-text css-0">{(tokenAmount).toLocaleString(undefined, { maximumFractionDigits: 2})} {selectedToken.symbol.replace(/( from PulseChain| from Ethereum)/g, '')}</b> from
                  Ethereum<div> and receive </div>
                  <b className="chakra-text css-0">{(tokenAmount).toLocaleString(undefined, { maximumFractionDigits: 2})} {selectedToken.symbol.replace(/( from PulseChain| from Ethereum)/g, '')}</b> on Pulsechain
                </span>
              </div>
            </div>
            <div className="css-166sium">
              <div className="css-70qvj9">
                <svg
                  viewBox="0 0 14 14"
                  focusable="false"
                  className="chakra-icon css-v9m5uc"
                >
                  <g fill="currentColor">
                    <polygon points="5.5 11.9993304 14 3.49933039 12.5 2 5.5 8.99933039 1.5 4.9968652 0 6.49933039" />
                  </g>
                </svg>
              </div>
              <div className="css-1wmrhmx">
                <p className="chakra-text css-vbpo87">Switch network</p>
              </div>
            </div>
            <div className="css-166sium">
              <div className="css-70qvj9">
                <svg
                  viewBox="0 0 14 14"
                  focusable="false"
                  className="chakra-icon css-v9m5uc"
                >
                  <g fill="currentColor">
                    <polygon points="5.5 11.9993304 14 3.49933039 12.5 2 5.5 8.99933039 1.5 4.9968652 0 6.49933039" />
                  </g>
                </svg>
              </div>
              <div className="css-1wmrhmx">
                <p className="chakra-text css-vbpo87">Claim Tokens</p>
              </div>
            </div>
            <div className="css-1igul4x">
              <div className="css-dwob7n">
                <p className="chakra-text css-ut5gn7">
                  Click Send to begin the transfer
                </p>
              </div>
            </div>
          </div>
          <div className="css-1agjl9p">
            <div className="css-0" />
          </div>
        </div>
        <footer className="chakra-modal__footer css-1rnuupr">
          { !isLoading ?
          <button
            className="css-18t9s5j"
            style={{
              opacity: 1,
              cursor: "pointer",
              pointerEvents: "all",
              textAlign: "center"
            }}
            onClick={action}
            disabled={isLoading}
          >
            <p className="chakra-text css-1kvtc9g">Send</p>
          </button>
          :
          <button className="css-5jgjvj">
            <div className="chakra-spinner css-mtfc5h">
              <span className="css-f8n5zr">Loading...</span>
            </div>
          </button> }
        </footer>
      </section>
    </div>
  </div>
  <div
    data-focus-guard="true"
    tabIndex={0}
    data-aria-hidden="true"
    aria-hidden="true"
    style={{
      width: 1,
      height: 0,
      padding: 0,
      overflow: "hidden",
      position: "fixed",
      top: 1,
      left: 1
    }}
  />
</div>


    )
}

export default BridgeDialogue