import './styles/transactions.css';

import { Link } from 'react-router-dom';

import noHistoryLogo from '../no-history.cc89800ba55dd715aaccbdb809ce3daa.svg';

function Transactions({action, isLoading}) {
    return(
        <>
            <div className="css-1v8anyy">
    <div className="css-1m0j6th">
        <div className="css-1jftm5g">
        <p className="chakra-text css-niwogb">
            Can't find your transfer to claim tokens?
        </p>
        <p className="chakra-text css-12s1uco">
            Enter the transaction hash where the token transfer happened{" "}
        </p>
        </div>
        <div className="chakra-input__group css-4302v8">
        <input
            placeholder="Transaction Hash"
            className="chakra-input css-4m5n9o"
            defaultValue=""
        />
        <div className="chakra-input__right-element css-y4iofe">
            <button disabled={isLoading} type="button" className="chakra-button css-1gzyfck" onClick={() => action()}>
            Claim
            </button>
        </div>
        </div>
    </div>
    <div className="css-1jrwjd3">
        <p className="chakra-text css-fpmzso">Transactions</p>
        <label className="chakra-checkbox css-20b3fg">
        <input
            className="chakra-checkbox__input"
            type="checkbox"
            defaultValue=""
            style={{
            border: 0,
            clip: "rect(0px, 0px, 0px, 0px)",
            height: 1,
            width: 1,
            margin: "-1px",
            padding: 0,
            overflow: "hidden",
            whiteSpace: "nowrap",
            position: "absolute"
            }}
        />
        <span
            className="chakra-checkbox__control css-1oi6yiz"
            aria-hidden="true"
        />
        <span className="chakra-checkbox__label css-7venl8">
            <p className="chakra-text css-o5yqp8">Show only unreceived</p>
        </span>
        </label>
    </div>
    <div className="css-1kiqrhc">
        <div className="css-oeczn3">
        <div className="css-1ljelbo">
            <div className="css-134yhvi" />
            <div className="css-106u1sa" />
            <div className="css-x4ra4d">
            <img
                src={noHistoryLogo}
                className="chakra-image css-uwwqev"
            />
            </div>
            <div className="css-106u1sa" />
            <div className="css-134yhvi" />
        </div>
        </div>
        <p className="chakra-text css-vv5iq">No History Found</p>
        <Link to="/">
        <button type="button" className="chakra-button css-1oaq8qa">
            Make Transfer
        </button>
        </Link>
    </div>
            </div>
        </>

    )
}

export default Transactions