function Footer () {
    return(
        <div className="css-kyc436">
        <div className="chakra-stack css-1g7us3y">
          <div className="css-1p21bk9">
            <a
              href="https://twitter.com/PulseChainCom"
              rel="noreferrer noopener"
              target="_blank"
            >
              <svg
                viewBox="0 0 18 16"
                focusable="false"
                className="chakra-icon css-onkibi"
              >
                <path
                  d="M18 1.89415C17.3306 2.21538 16.6174 2.42831 15.8737 2.53169C16.6388 2.032 17.2226 1.24677 17.4971 0.300308C16.7839 0.765538 15.9964 1.09415 15.1571 1.27754C14.4799 0.488616 13.5146 0 12.4616 0C10.4186 0 8.77387 1.81415 8.77387 4.03815C8.77387 4.35815 8.79862 4.66585 8.85938 4.95877C5.7915 4.79508 3.07687 3.18646 1.25325 0.736C0.934875 1.34031 0.748125 2.032 0.748125 2.77662C0.748125 4.17477 1.40625 5.41415 2.38725 6.13169C1.79437 6.11938 1.21275 5.93108 0.72 5.63446C0.72 5.64677 0.72 5.66277 0.72 5.67877C0.72 7.64062 1.99912 9.27015 3.6765 9.64554C3.37612 9.73538 3.04875 9.77846 2.709 9.77846C2.47275 9.77846 2.23425 9.76369 2.01038 9.70954C2.4885 11.3083 3.84525 12.4837 5.4585 12.5218C4.203 13.5963 2.60888 14.2437 0.883125 14.2437C0.5805 14.2437 0.29025 14.2289 0 14.1883C1.63462 15.3415 3.57188 16 5.661 16C12.4515 16 16.164 9.84615 16.164 4.512C16.164 4.33354 16.1584 4.16123 16.1505 3.99015C16.8829 3.42154 17.4982 2.71138 18 1.89415Z"
                  fill="currentColor"
                />
              </svg>
            </a>
          </div>
          <div className="css-1p21bk9">
            <a
              href="https://t.me/PulseChainCom"
              rel="noreferrer noopener"
              target="_blank"
            >
              <svg
                viewBox="0 0 18 16"
                focusable="false"
                className="chakra-icon css-onkibi"
              >
                <path
                  d="M7.06305 10.2193L6.7653 14.4074C7.19131 14.4074 7.37581 14.2244 7.59707 14.0046L9.59436 12.0958L13.733 15.1267C14.492 15.5497 15.0267 15.3269 15.2315 14.4284L17.9481 1.69909L17.9488 1.69834C18.1896 0.576318 17.5431 0.137557 16.8035 0.412814L0.835653 6.52621C-0.254123 6.94922 -0.237622 7.55673 0.650399 7.83199L4.73275 9.10177L14.2152 3.16838C14.6615 2.87287 15.0672 3.03638 14.7335 3.33188L7.06305 10.2193Z"
                  fill="currentColor"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    )
}

export default Footer