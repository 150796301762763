import React, {
  useEffect,
  useState,
} from 'react';

import { useGatekeeper } from "../context/Gatekeeper";

import {
  useLocation,
  useNavigate,
} from 'react-router-dom';

import {
  useDisconnect,
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from '@web3modal/ethers5/react';

import {
  switchNetwork
} from '../utils/helpers';
import Body from './Body';
import Claim from './Claim';
import Footer from './Footer.jsx';
import Header from './Header';
import NetworkDialogue from './NetworkDialogue.jsx';
import Transactions from './Transactions';
import useWeb3Actions from './hooks/useWeb3Actions';

function Bridge() {
  const { open } = useWeb3Modal();
  const { disconnect } = useDisconnect();
  const [isDropDownSelect, setIsDropDownSelect] = useState(false);
  const [connectClick, setConnectClick] = useState(false);

  const { isLoading, setIsLoading } = useGatekeeper();
  const { action } = useWeb3Actions({ isLoading, setIsLoading })

  const { address, chainId, isConnected } = useWeb3ModalAccount()
  const { walletProvider } = useWeb3ModalProvider()

  const navigate = useNavigate();
  const location = useLocation();
  const name = location.pathname.replace('/', '')
  const pageName = name.charAt(0).toUpperCase() + name.slice(1);
  
  useEffect(() => {
      const canonicalUrl = window.location.href;
      const canonicalLink = document.querySelector("link[rel='canonical']") 
  
      canonicalLink.href = canonicalUrl;
  
      document.title = location.pathname === '/claim' ? 'PulseChain Bridge | '+pageName : 'PulseChain Bridge'
  }, [location.pathname]);

  return (
    <>
      <div className="css-1xzyxrv">
        <div className="css-gem0i">
          <Header
            isDropDownSelect={isDropDownSelect}
            setIsDropDownSelect={setIsDropDownSelect}
            connectClick={connectClick}
            setConnectClick={setConnectClick}
            isConnected={isConnected}
            address={address}
            open={open}
            disconnect={disconnect}
            chainId={chainId}
          />

          {location.pathname === "/" && (
            <Body
              isLoading={isLoading}
              open={open}
              isConnected={isConnected}
              action={action}
              connectClick={connectClick}
              setConnectClick={setConnectClick}
              chainId={chainId}
              walletProvider={walletProvider}
              address={address}
            />
          )}

          {location.pathname === "/claim" && (
            <Claim isConnected={isConnected} action={action} open={open} isLoading={isLoading} />
          )}

          {location.pathname === "/transactions" && (
            isConnected ? <Transactions action={action} isLoading={isLoading}/> : navigate('/')
          )}
        </div>
        <Footer />
        {chainId && !(chainId === 1 || chainId === 369) && <NetworkDialogue switchNetwork={switchNetwork} walletProvider={walletProvider}/> }
    
      </div>
    </>
  );
}

export default Bridge;
