import './styles/networkdialogue.css';

import { chains } from '../utils/chains';

const NetworkDialogue = ({switchNetwork, walletProvider}) => {



    return(
<div className="chakra-modal__overlay css-14h5w54" style={{ opacity: 1 }}>
  <div
    data-focus-guard="true"
    tabIndex={0}
    data-aria-hidden="true"
    aria-hidden="true"
    style={{
      width: 1,
      height: 0,
      padding: 0,
      overflow: "hidden",
      position: "fixed",
      top: 1,
      left: 1
    }}
  />
  <div
    data-focus-guard="true"
    tabIndex={1}
    data-aria-hidden="true"
    aria-hidden="true"
    style={{
      width: 1,
      height: 0,
      padding: 0,
      overflow: "hidden",
      position: "fixed",
      top: 1,
      left: 1
    }}
  />
  <div data-focus-lock-disabled="false">
    <div className="chakra-modal__content-container css-1yb2anq" tabIndex={-1}>
      <div
        className="sc-jSwlEQ sc-bhFOXJ eYiExZ lpumgW"
        style={{ overflow: "visible" }}
      >
        <div className="sc-gKHVLF jZrpqH">
          {/*<div className="sc-gKHVLF sc-iBAaJG sc-WSdAm bjzToG lbwIxd mwBGt">
            <div className="sc-gKHVLF sc-iBAaJG sc-ikzIVP UlmxL lbwIxd iYobww">
              <h2 className="sc-grXZZQ sc-AOXSc jftdDy eONOKz">
                Check your network
              </h2>
            </div>
  </div>*/}
          <div
            className="sc-gKHVLF sc-iBAaJG sc-tIxES gCFhHB lbwIxd jRRtdU"
            style={{ alignItems: "normal" }}
          >
            <div
              className="sc-gKHVLF sc-bqOBKd ekzFoj hMqCPK"
              style={{ gap: 20 }}
            >
              <div className="sc-UhFNL gpHEO">
                <div className="sc-gKHVLF bAaMmy">
                  <svg
                    viewBox="0 0 24 24"
                    color="warning"
                    width="24px"
                    xmlns="http://www.w3.org/2000/svg"
                    className="sc-bcPKhP inGDOB"
                  >
                    <path d="M4.47 20.9999H19.53C21.07 20.9999 22.03 19.3299 21.26 17.9999L13.73 4.98993C12.96 3.65993 11.04 3.65993 10.27 4.98993L2.74 17.9999C1.97 19.3299 2.93 20.9999 4.47 20.9999ZM12 13.9999C11.45 13.9999 11 13.5499 11 12.9999V10.9999C11 10.4499 11.45 9.99993 12 9.99993C12.55 9.99993 13 10.4499 13 10.9999V12.9999C13 13.5499 12.55 13.9999 12 13.9999ZM13 17.9999H11V15.9999H13V17.9999Z" />
                  </svg>
                </div>
                <div className="sc-grXZZQ bXKYXq">
                  Oops, You seem to be on the wrong network
                </div>
              </div>
              <button className="sc-hLclGa iWOLBh" onClick={() => switchNetwork(chains[369].hexChainId, walletProvider)}>
                Switch network to PulseChain
              </button>
              <p style={{ textAlign: "center", color: "#fff" }}>or</p>
              <button className="sc-hLclGa emszuM" onClick={() => switchNetwork(chains[1].hexChainId, walletProvider)}>
                Switch network to Ethereum
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    data-focus-guard="true"
    tabIndex={0}
    data-aria-hidden="true"
    aria-hidden="true"
    style={{
      width: 1,
      height: 0,
      padding: 0,
      overflow: "hidden",
      position: "fixed",
      top: 1,
      left: 1
    }}
  />
</div>


    )
}

export default NetworkDialogue