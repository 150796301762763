import './App.css';

import React from 'react';

import { GatekeeperProvider } from "./context/Gatekeeper";

import {
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';

import {
  createWeb3Modal,
  defaultConfig,
} from '@web3modal/ethers5/react';

import Bridge from './components/Bridge';
import {
  mainnet,
  pulsechain,
} from './utils/chains';

const projectId = process.env.REACT_APP_PROJECT_ID

const metadata = {
  name: 'PulseChain Bridge',
  description: 'Velodrome Finance: The central liquidity hub on Optimism network.',
  url: 'https://bridge.pulsechain.com/',
  icons: ['https://bridge.pulsechain.fi/logo.svg']
}

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata, defaultChainId: 1 }),
  chains: [ pulsechain, mainnet ],
  projectId,
  theme: 'light',
  excludeWalletIds: [
    'a797aa35c0fadbfc1a53e7f675162ed5226968b44a19ee3d24385c64d1d3c393'
  ]
})

function App() {
  
  return (
    <Router>
      <GatekeeperProvider>
        <Routes>
          <Route index element={<Bridge />} />
          <Route path="/claim" element={<Bridge />} />
          <Route path="/transactions" element={<Bridge />} />
        </Routes>
      </GatekeeperProvider>
    </Router>
  );
}

export default App;
