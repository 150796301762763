function Claim({ isConnected, action, open, isLoading}) {
  return (
    <div className="css-1ku6y4o1">
      <div className="css-1tbe0jg">
        <div className="css-wy46cx">
          <div className="cob">
            <p className="chakra-text css-1snmjsk">Click to Check Rewards</p>
          </div>
        </div>
        <button
          type="button"
          className={`chakra-button ${
            isConnected ? `css-gzftu` : `css-1jyyy0x`
          }`}
          justify="cener"
          onClick={() => (isConnected ? action() : open())}
          disabled={isLoading}
        >
          <p className="chakra-text css-13h4elh">
            {isConnected ? "Claim" : "Connect"}{" "}
          </p>
        </button>
        <div className="css-wy46cx" />
      </div>
    </div>
  );
}

export default Claim;
